<template>

    <div class="hidden lg:block">

        <div class="w-full flex gap-5 mx-auto">

            <div class="p-10 border-2 border-dark-blue rounded-md leading-tight max-w-[455px] flex flex-col justify-center items-center">
                <p class="text-28 mt-1 font-black">
                    <span class="font-black uppercase">Obrigado pela sua participação</span>
                </p>
                <p class="text-24 mt-2 font-regular">A apuração está sendo realizada e o resultado será divulgado no
                    <span>dia 19/12.</span></p>
            </div>

            <div class="block mx-auto w-full h-auto">
                <hero-image />
            </div>

        </div>

    </div>

    <div class="block lg:hidden">

        <div class="block mx-auto w-full h-auto mt-5 mb-8">
            <hero-image />
        </div>

        <p class="text-18">Mostre seu jeitinho de celebrar com Riberalves e <span class="font-extrabold">concorra a uma viagem com acompanhante!</span></p>
        <p class="text-18">Toda família tem uma receita especial com Bacalhau, cheia de sabor e tradição, e a Riberalves quer conhecer a sua!</p>

    </div>

</template>

<script setup>
</script>
