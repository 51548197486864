<template>

    <div class="w-full max-w-[670px] max-w-lg lg:max-w-full flex flex-col lg:flex-row gap-5 mx-auto">

        <div class="block mx-auto w-full h-auto">
            <hero-image />
        </div>

        <div class="p-10 border-2 border-dark-blue rounded-md leading-tight lg:max-w-[490px]">
            <h1 class="text-24 font-bold mb-4">A campanha Receita que te leva para Portugal foi um sucesso, e o resultado já saiu!</h1>
            <p class="mb-6"><span class="font-18 font-bold">Confira os vencedores:</span></p>

            <!-- 1º Lugar -->
            <div class="mb-6">
                <div class="flex items-start mb-2">
                    <img src="@/assets/images/medal-1st.png" alt="" class="-ml-3 mt-2" />
                    <div class="text-18 tracking-tighter">
                        <p class="mb-1"><span class="font-bold">1º lugar:</span> Rodolfo Francisco Vieira</p>
                        <p class="mb-1"><span class="font-bold">Receita:</span> Bacalhau do Português</p>
                        <p class="mb-1"><span class="font-bold">Prêmio:</span> Viagem de 5 dias para duas pessoas a Portugal!</p>
                    </div>
                </div>
            </div>

            <!-- 2º Lugar -->
            <div class="mb-6">
                <div class="flex items-start mb-2 border-t border-dark-blue pt-4">
                    <img src="@/assets/images/medal-2st.png" alt="" class="-ml-3 mt-2" />
                    <div class="text-18 tracking-tighter">
                        <p class="mb-1"><span class="font-bold">2º lugar:</span> José Antônio de Souza Buere Filho</p>
                        <p class="mb-1"><span class="font-bold">Receita:</span> Salada de Bacalhau com Feijão-Branco, Manga e Maçã</p>
                        <p class="mb-1"><span class="font-bold">Prêmio:</span> Kit especial com produtos Riberalves.</p>
                    </div>
                </div>
            </div>

            <!-- 3º Lugar -->
            <div class="mb-6 border-t border-dark-blue pt-4">
                <div class="flex items-start mb-2">
                    <img src="@/assets/images/medal-3st.png" alt="" class="-ml-3 mt-2" />
                    <div class="text-18 tracking-tighter">
                        <p class="mb-1"><span class="font-bold">3º lugar:</span> Marinalva Almeida de Abreu Garcia</p>
                        <p class="mb-1"><span class="font-bold">Receita:</span> Barquetes de Bacalhau</p>
                        <p class="mb-1"><span class="font-bold">Prêmio:</span> Kit especial com produtos Riberalves.</p>
                    </div>
                </div>
            </div>

            <p class="mt-6"><span class="font-17">Agradecemos a todos que participaram, compartilhando receitas incríveis e mostrando o jeitinho brasileiro de celebrar. Até a próxima celebração!</span></p>

        </div>

    </div>

</template>

<script setup>
</script>
